import logo from './logo.svg';
import './App.css';
import { ConnectButton } from "360dialog-connect-button";

function App() {
  const handleCallback = (callbackObject) => {
    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */
 
    console.log("client ID: "+callbackObject.client)
    console.log("channel IDs: " + callbackObject.channels);
    alert('Cliente ID'+callbackObject.client+' , Channel ID '+callbackObject.channels+' . Por favor enviar estos datos a SherpaxIT. Gracias.');
  }

  return (
    <div className="App">
      <p> SherpaxIT Onboarding</p>
      
      <ConnectButton label="Validar whatsapp business account" 
        partnerId={'DIe6WEPA'}
        callback={handleCallback}
      />
    </div>
  );
}

export default App;
